@import '../../../styles/customMediaQueries.css';

.root {
  background-color: white;
  min-height: unset;
  display: block;

  @media (--viewportMedium) {
    min-height: 100%;
  }

  @media (--viewportMSScreen) {
    display: none;
  }
}

.container {
  /* background-color: #fff8fe; */
  min-height: unset;

  @media (--viewportMedium) {
    min-height: 100%;
  }
}

.brandWrap {
  flex-direction: column;
  display: flex;
  gap: 56px;
  padding: 64px 80px 0;
  align-items: center;
  
  @media(--viewportXLarge){
    max-width: var(--contentLgMaxWidthPages);
    margin: 0 auto;
    padding: 64px 0;
  }
  @media (--viewportMediumScreen){
    max-width: 100%;
    padding: 60px;
  }
}

.brandTitle {
    font-size: 18px;
    color: #191A1F;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
}

.brandLogoContainer {
  align-items: center;
  display: flex;
  flex: 1 1;
  gap: 15px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.brandLogo {
  width: 15%;
  text-align: center;
}