@import '../../../styles/customMediaQueries.css';

.root {
    min-height: unset;
    padding: 100px 0;
    background-color: #fff;

    @media (--viewportMedium) {
        min-height: 100%;
    }

    @media (--viewportMediumScreen){
        padding: 100px 0;
    }

    @media(--viewportMSScreen) {
        padding: 50px 0;
    }

    @media (--viewportFinalSmallScreen){
        padding: 70px 0 60px;
    }
}

.container {
    /* background-color: #fff8fe; */
    min-height: unset;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto;
    
    @media (--viewportXLarge) {
        max-width: var(--contentLgMaxWidthPages);
    }

    @media (--viewportMedium) {
        min-height: 100%;
    }

    @media (--viewportMediumScreen){
        max-width: 100%;
        padding: 0 60px;
    }

    @media (--viewportFinalSmallScreen){
        padding: 0 20px;
    }
}

.learnTitle {
    margin: 0 0 56px;
    padding: 0;
    font-size: 48px;
    line-height: 56px;
    font-weight: 800;
    color: #191A1F;

    @media (--viewportLarge) {
        font-size: 48px;
        line-height: 1.2;
    }

    @media (--viewportXMScreen) {
        font-size: 36px;
        line-height: 46px;
    }
    @media (--viewportXSScreen){
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 30px;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 32px;
    }
}

.learnGrowWrap {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (--viewportXSScreen){
        flex-direction: column;
    }
}

.learnGrowCard {
    background-color: #F3F4F2;
    border-radius: 32px;
    padding: 8px;

    @media (--viewportLarge) {
        width: 50%;
    }
}

.learnGrowImgFinal, .learnGrowImg {
    width: 100%;
}

.learnGrowContent {
    padding: 24px;
}

.learnGrowLabel, .learnGrowLabelBlue {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
}

.learnGrowLabel {
    color: #E08E00;
}

.learnGrowLabelBlue {
    color: #4952F7;
}

.learnGrowContWrap {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.learnGrowTitle {
    font-size: 20px;
    line-height: 24px;
    color: #191A1F;
    font-weight: 500;
    margin: 0 0 5px;
    padding: 0;

    @media (--viewportFinalSmallScreen){
        font-size: 16px;
        line-height: 22px;
    }
}

.learnGrowCaption {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #191A1F;
    padding: 0;
    margin: 0;
}

.learnGrowButton {
    padding: 12px 32px;
    border-radius: 48px;
    background-color: #191A1F;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 15px;

    @media (--viewportFinalSmallScreen){
        font-size: 15px;
    }
}