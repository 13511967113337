@import '../../../styles/customMediaQueries.css';

.root {
    min-height: unset;
    padding: 0;

    @media (--viewportMedium) {
        min-height: 100%;
    }
}

.container {
    background-color: #fff;
    min-height: unset;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 32px;

    @media (--viewportXLarge) {
        max-width: var(--contentLgMaxWidthPages);
        padding: 0;
    }

    @media (--viewportMedium) {
        min-height: 100%;
    }

    @media (--viewportFinalSmallScreen){
        padding: 0 20px;
    }
}

.showCaseWrap {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    @media (max-width: 767px){
        flex-direction: column;
    }
}

.showCaseCard {
    padding: 16px 32px 32px;
    border-radius: 64px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
    width: 50%;

    @media (max-width: 767px){
        width: 100%;
    }

    @media (--viewportXSScreen){
        gap: 32px;
        border-radius: 20px;
        padding: 16px;
    }
}

.showCaseCont {
    margin: 120px auto 0;
    max-width: 75%;

    @media (--viewportLarge) {
        max-width: 90%;
    }

    @media (--viewportMSScreen) {
        width: 100%;
    }

    @media (--viewportXSScreen){
        margin: 30px auto 0;
        max-width: 100%;
    }
}

.showCaseTitle {
    font-size: 48px;
    line-height: 56px;
    font-weight: 800;
    text-align: center;
    margin: 0 0 10px;
    padding: 0;

    @media (--viewportLarge) {
        font-size: 48px;
        line-height: 1.2;
    }

    @media (--viewportXMScreen) {
        font-size: 36px;
        line-height: 46px;
    }

    @media (--viewportXSScreen){
        font-size: 32px;
        line-height: 42px;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 28px;
        line-height: 38px;
    }
}

.showCaseContent {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;

    @media (--viewportXSScreen){
        font-size: 14px;
    }
}

.showCaseButton {
    background-color: #F3F4F2D9;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 32px 4px 4px;
    border-radius: 48px;
    font-size: 16px;
    color: #191A1F;
    line-height: 20px;
    font-weight: 500;

    @media (--viewportXSScreen){
        font-size: 15px;
    }
}

.showCaseButtonIcon {
    background-color: #fff;
    padding: 10px;
    border-radius: 48px;
}

.showCaseImage {
    width: 100%;
}

.showCaseImageFinal {
    width: 100%;
    object-fit: cover;
}