@import '../../../styles/customMediaQueries.css';
.root {
  background-color: var(--backgroundColor);
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    /* height: 800px; */
  }
}
.leftChild {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  text-align: center;
  @media (--viewportMedium) {
    align-items: center;
    justify-content: center;
  }
}
.heading {
  width: 80%;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--colorBlack);
  @media (--viewportMedium) {
    font-size: 40px;
  }
}
.image {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  object-fit: contain;
}
.button {
  border: 2px solid var(--marketplaceColor);
  width: 100%;
  max-width: auto;
  padding: 0.5rem;
  cursor: pointer;
  @media (--viewportMedium) {
    width: 350px;
    max-width: unset;
  }
}
