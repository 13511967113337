@import '../../../styles/customMediaQueries.css';
.root {
  background-color: var(--colorWhite);
}
.title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: unset;
  padding-top: 20px;
  @media (--viewportMedium) {
    font-size: 50px;
  }
}
.container {
  display: none;
  flex-direction: column;
  gap: 1rem;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem 2rem;
    padding-bottom: 5rem;
  }
}
.mobileContainer {
  padding: 1rem;
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.main {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  padding: 1rem 0rem;
  border-bottom: 8px solid var(--backgroundColor);
}
.sub {
  font-size: 20px;
  line-height: 1.9;
  /* padding: 1rem 1.5rem; */
  /* padding: 1rem; */
  padding-right: 0;
  margin: 0;
  /* border-left: 3px solid var(--backgroundColor); */
  @media (--viewportMedium) {
    /* padding: 1rem 3rem; */
  }
  @media (--viewportLarge) {
    /* padding: 1rem 5rem; */
  }
}

.subText {
  font-size: 18px;
}
.subDivide {
}
.circle {
  display: none;
  @media (--viewportMedium) {
    display: block;
    position: absolute;
    margin-left: -10px;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    border: 1px solid var(--backgroundColor);
    border-radius: 50%;
    background-color: var(--backgroundColor);
  }
}
.divider {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4px;
    /* height: 100%; */
    background-color: var(--backgroundColor);
  }
}
.rightChild {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
