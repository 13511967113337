@import '../../../styles/customMediaQueries.css';
.root {
  background-color: var(--backgroundColor);
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  padding-top: 6rem;
}
.title {
  font-size: 20px;
  font-weight: 400;
  color: var(--colorBlack);
  text-transform: uppercase;
  @media (--viewportMedium) {
    font-size: 30px;
  }
}
.imageContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 500px);
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 600px;
  }
}
.leftChild,
.rightChild {
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Adding transition for opacity */
}

.leftChild:hover,
.rightChild:hover {
  opacity: 0.8;
}

.image {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  object-fit: cover;
}

.hoverTextContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  @media (--viewportMedium) {
    opacity: 1;
  }
}

.leftChild:hover .hoverTextContainer,
.rightChild:hover .hoverTextContainer {
  opacity: 1;
}

.leftText,
.rightText {
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  color: var(--colorWhite);
  @media (--viewportMedium) {
    font-size: 30px;
  }
}
