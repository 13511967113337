@import '../../../styles/customMediaQueries.css';

.root {
    background-color: white;
    min-height: unset;

    @media (--viewportMedium) {
        min-height: 100%;
    }
}

.container {
    /* background-color: #fff8fe; */
    min-height: unset;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto;

    @media (--viewportXLarge) {
        max-width: var(--contentLgMaxWidthPages);
    }

    @media (--viewportMedium) {
        min-height: 100%;
    }

    @media (--viewportMediumScreen){
        max-width: 100%;
        padding: 0 60px 80px;
    }

    @media (--viewportXSScreen){
        padding: 0 20px 56px;
    }
}

.heading {
    text-align: center;
    margin: 0;
    font-size: 25px;
    color: var(--colorBlack);
    padding-top: unset;
    font-family: "Lexend", sans-serif;
    font-weight: 800;

    @media (--viewportLarge) {
        font-size: 48px;
        padding: 100px 0;
    }

    @media (--viewportXMScreen){
        font-size: 36px;
        line-height: 46px;
        padding: 50px 0 60px;
    }

    @media (--viewportXSScreen){
        font-size: 32px;
        line-height: 42px;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 28px;
        line-height: 38px;
    }

    /* margin-bottom: -4rem; */
}

.partnerIconContWrap {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (--viewportMSScreen){
        flex-direction: column;
        padding: 0 !important;
    }
    
}

.partnerLabel {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    font-weight: 500;
    background-color: #500543;
    padding: 12px 16px;
    border-radius: 50px;
}

.partnerHeading {
    text-align: center;
    margin: 30px 0 46px;
    font-size: 25px;
    color: #191A1F;
    padding-top: unset;
    font-family: "Lexend", sans-serif;
    text-align: left;
    display: inline-block;
    width: 80%;
    font-weight: 800;

    @media (--viewportXMScreen) {
        font-size: 36px;
        line-height: 46px;
    }

    @media (--viewportLarge){
        font-size: 48px;
        line-height: 1.2;
    }

    @media (--viewportXSScreen){
        font-size: 32px;
        line-height: 42px;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 29px;
        line-height: 38px;
    }

    /* margin-bottom: -4rem; */
}

.partnerIconCont {
    display: flex;
    align-items: start;
    gap: 15px;
    margin-bottom: 16px;
    position: relative;

    @media (--viewportFinalSmallScreen){
        flex-direction: column;
    }
}

.partnerIconCont:not(:last-child) {
    border-bottom: 1px solid #191A1F1A;
    padding-bottom: 16px;
}

.partnerInnerHeading {
    font-size: 20px;
    font-weight: var(--fontWeightMedium);
    line-height: 24px;
    color: #191A1F;
    margin: 0;

    @media (--viewportXMScreen) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 16px;
        line-height: 22px;
    }
}

.partnerText {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: #191A1F;
    margin: 0;

    @media (--viewportXMScreen) {
        font-size: 14px;
        line-height: 20px;
    }
}

.partnerIconContWrap {
    padding-bottom: 120px;

    @media (--viewportMediumScreen){
        padding-bottom: 0;
    }
}

.partnerRightImg, .partnerContWrap {
    @media (--viewportXMScreen){
        width: 50%;
    }

    @media (--viewportMSScreen){
        width: 100%;
    }
}

.partnerRightImgFinal {
    @media (--viewportXMScreen){
        width: 100%;
    }
}

.partnerIconWrap img {
    filter: brightness(0) saturate(100%) invert(12%) sepia(92%) saturate(1500%) hue-rotate(300deg) brightness(90%) contrast(85%);
}