@import '../../../styles/customMediaQueries.css';
.root {
  background-color: #fafafa;
  /* background-color: var(--colorWhite); */
  padding-bottom: 2rem;
  @media (--viewportMedium) {
    padding: 1rem;
  }
}
.sections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 3rem; */
  max-width: 1120px;
  margin: auto;
}
.title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: unset;
  padding-top: 20px;
  @media (--viewportMedium) {
    font-size: 30px;
  }
}
.subheading {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: unset;
  line-height: 1.1;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}
.h3 {
  font-size: 16px;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}
.container {
  display: flex;
  align-items: center;
  text-align: left;
  height: 70%;
}
.iconContainer {
  display: flex;
  justify-content: flex-end;
  /* width: unset;
  @media (--viewportMedium) {
    width: 40%;
  } */
}
.mainContainer {
  width: 60%;
}
.icon {
  font-size: 90px;
  color: var(--marketplaceColor);
  margin-right: 1rem;
}
.text {
  margin: 0;
}
.section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 2rem;
  align-self: center;
  max-width: 100%;
  margin-top: 0;
  padding: 4rem 0rem;
  border-bottom: 1px solid var(--colorGrey100);
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 350px;
  }
}
.leftChild {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightChild {
  margin-top: 0rem;
  @media (--viewportMedium) {
    margin-top: 2.5rem;
    margin-right: 0.5rem;
  }
}
.imageContainer {
  border: 1px solid var(--colorGrey100);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1rem;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.button {
  border: 1px solid var(--marketplaceColor);
  padding: 0.5rem 2.5rem;
  color: white;
  background-color: var(--marketplaceColor);
  border-radius: 8px;
  margin: auto;
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
