@import '../../../styles/customMediaQueries.css';

.container {
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;

    @media (--viewportXLarge) {
        max-width: var(--contentLgMaxWidthPages);
    }

    @media (--viewportMediumScreen){
        max-width: 100%;
        padding: 0 60px;
    }

    @media (--viewportFinalSmallScreen){
        padding: 0 20px;
    }
}

.brandLoveWrap {
    padding: 200px 0;
    background-color: #fff;
    display: none;

    @media (--viewportMediumScreen){
        padding: 120px 0 140px;
    }

    @media (--viewportFinalSmallScreen){
        padding: 70px 0 90px;
    }

    @media (--viewportMSScreen){
        display: block;
        padding: 50px 0 110px;
    }
}

.brandLoveContainer {
    position: relative;
}

.brandLoveSlider {
    background-color: #F3F4F2;
    padding: 32px;
    border-radius: 32px;
    margin: 0 10px;
    height: 100%;
}

.brandSliderCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 32px;
}

.brandAuthWrap {
    display: flex;
    align-items: start;
    gap: 15px;
}

.brandSlideContWrap {
    text-align: left;
}

.brandSlideHead {
    font-size: 18px;
    line-height: 25px;
    color: #191A1F;
    font-weight: var(--fontWeightMedium);
    margin: 0;
    padding-bottom: 5px;

    @media (--viewportFinalSmallScreen){
        font-size: 16px;
    }
}

.brandSlideLabel {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #191A1F;
    margin: 0;
    padding: 0;
}

.text {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: #191A1F;
    text-align: left;
    margin: 0;
    padding: 0;

    @media (--viewportFinalSmallScreen){
        font-size: 14px;
    }
}

.leftArrow,
.rightArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: absolute;
    top: -60px;
    z-index: 99;
    bottom: auto;
    border: 0;
}

.leftArrow {
    left: auto;
    right: 50px;
}

.rightArrow {
    right: 0;
}

.leftArrow:hover,
.rightArrow:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.leftArrow:disabled,
.rightArrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.arrow {
    width: 20px;
    height: auto;
}

.carousel.carousel-slider {
    overflow: visible;
}
.brandLoveHeading {
    font-size: 25px;
    color: #191A1F;
    font-family: "Lexend", sans-serif;
    text-align: left;
    font-weight: 800;

    @media (--viewportLarge) {
        font-size: 48px;
        line-height: 1.2;
    }

    @media (--viewportXMScreen) {
        font-size: 36px;
        line-height: 46px;
    }
    @media (--viewportXSScreen){
        font-size: 32px;
        line-height: 42px;
        margin-top: 0;
    }

    @media (--viewportFinalSmallScreen){
        font-size: 28px;
        line-height: 38px;
        font-size: 28px;
        line-height: 38px;
        margin-top: 0;
        padding-top: 0;
        width: 50%;
    }
}