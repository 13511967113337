@import '../../../styles/customMediaQueries.css';
.root {
  background-color: white;
  min-height: unset;
  position: relative;
  @media (--viewportMedium) {
    min-height: 1200px;
  }
  @media (--viewportLarge) {
    min-height: 1200px;
    /* margin-top: -8rem; */
  }
}
.container {
  /* background-color: #fff7f0; */
  min-height: 700px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 247, 240, 0),
    #fff7f0 20%,
    #fff7f0 80%,
    rgba(255, 247, 240, 0)
  );
  @media (--viewportSmall) {
    min-height: 700px;
  }
  @media (--viewportMedium) {
    min-height: 900px;
  }
  @media (--viewportLarge) {
    min-height: 900px;
  }
  @media (--viewportLargeWithPaddings) {
    min-height: 900px;
  }
}
.heading {
  text-align: center;
  margin: 0;
  /* background-color: #fff7f0; */
  background-image: linear-gradient(
    to bottom,
    rgba(255, 247, 240, 0),
    #fff7f0 20%,
    #fff7f0 5%,
    rgba(255, 247, 240, 0)
  );
  font-size: 25px;
  padding: 2rem;
  font-family: Poppins Bold;
  color: #500543;
  /* color: var(--colorBlack); */
  @media (--viewportLarge) {
    font-size: 48px;
  }
  /* margin-bottom: -4rem; */
}

.bgLine {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url('../../../assets/photos/lastLine.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 600px;
}
.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 700px;
}
.absoluteContainer {
  position: static;
  margin: 1rem;
  @media (--viewportLarge) {
    position: absolute;
    margin: unset;
    left: 14%;
  }
}
.imageContainer {
  position: relative;
  width: 100%;
  z-index: 1;
  @media (--viewportLarge) {
    /* width: 70%; */
    width: 100%;
  }
  /* width: 96%; */
}
.buttonContainer {
  position: absolute;
  bottom: 20px;
}
.heroButton {
  /* #FEAF64,#F83194 */
  background: linear-gradient(90deg, rgba(248, 49, 148, 1) 0%, #feaf64 100%);
  border-radius: 50px;
  color: white;
  font-size: 14px;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  margin-top: 0.35rem;
  &:hover {
    text-decoration: none;
  }
}
.image {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border-radius: 25px;
  @media (--viewportMedium) {
    height: 600px;
    width: 70%;
  }
}
.imageText {
  display: block;
  position: static;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  height: unset;
  padding: 1.5rem 2rem;
  margin-top: 0.5rem;
  z-index: 1;
  @media (--viewportLarge) {
    display: none;
    position: absolute;
    width: 490px;
    height: 320px;
    /* height: 460px; */
    top: 40%;
    /* top: 20%; */
    right: -20%;
    padding: 2rem 2rem;
    margin-top: unset;
  }
}
.imageTextDesktop {
  display: none;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  height: unset;
  padding: 1.5rem 2rem;
  margin-top: 0.5rem;
  text-align: left;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    width: 490px;
    height: 320px;
    /* height: 460px; */
    top: 40%;
    /* top: 20%; */
    right: -20%;
    padding: 2rem 1.5rem;
    margin-top: unset;
  }
}
.imageHeading {
  margin: 0;
  line-height: 1.2;
  font-size: 25px;
  color: #500543;
  /* color: var(--colorBlack); */
  @media (--viewportLarge) {
    font-size: 30px;
  }
}
.text {
  margin: 0;
  line-height: 23px;
}
.groupHeading {
  color: white;
  @media (--viewportLarge) {
    font-size: 40px;
    margin-bottom: 0.5rem;
  }
}
.groupContainer {
  display: flex;
  justify-content: center;
}
.group {
  background: linear-gradient(90deg, rgba(248, 49, 148, 1) 0%, #feaf64 100%);
  border-radius: 30px;
  align-self: center;
  position: relative;
  margin: 1rem;
  width: 100%;
  margin-top: 2rem;
  min-height: 250px;

  @media (--viewportLarge) {
    margin: 2rem;
    width: 73%;
    margin-top: -10rem;
  }
}
.groupImage {
  width: 100%;
  object-fit: cover;
}
.groupText {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.groupHeading {
  margin: 0;
}
.creatorLabel {
  font-size: 70px;
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
}
.groupButton {
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  font-size: 14px;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  @media (--viewportLarge) {
    margin-top: 2rem;
  }
  &:hover {
    text-decoration: none;
  }
}
.redDot {
  color: #e30093;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 20%;
  left: 60%;
}
.redDotBig {
  color: #ff5295;
  font-size: 100px;
  margin-right: 0.5rem;
  position: absolute;
  top: 80%;
  left: 5%;
}
.orangeDot {
  color: #500543;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 63%;
  left: 30%;
}
.orangeDotRight {
  color: #ffc24f;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 55%;
  right: 20%;
}
.redDotRight {
  color: #e30093;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 70%;
  right: 5%;
}
.redSmallLeft {
  color: #ff5295;
  font-size: 100px;
  margin-right: 0.5rem;
  position: absolute;
  top: 20%;
  left: 5%;
}
.orangeDotTopRight {
  color: #ff827e;
  font-size: 140px;
  margin-right: 0.5rem;
  position: absolute;
  top: 5%;
  right: 5%;
}
.joinTeamImage {
  z-index: 1;
}
.leftArrow {
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 20px;
  background-color: white;
  border: 1px solid white;
  padding: 0.75rem;
  border-radius: 30px;
  cursor: pointer;
  @media (--viewportMedium) {
    top: 85%;
    left: 20px;
  }
}
.rightArrow {
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 75%;
  background-color: white;
  border: 1px solid white;
  padding: 0.75rem;
  /* width: 48px; */
  border-radius: 30px;
  cursor: pointer;
  @media (--viewportMedium) {
    top: 85%;
    left: 8%;
  }
}
.arrow {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.images {
  position: relative;
  width: 100%;
  @media (--viewportLarge) {
    width: 70%;
  }
}
.lineOne {
  position: absolute;
  transition: transform 0.3s ease;
  width: 100%;
  top: -20%;
  /* transform: rotate(20deg); */
  z-index: 0;
}
.lineTwo {
  position: absolute;
  transition: transform 0.3s ease;
  width: 100%;
  top: 60%;
  /* transform: rotate(20deg); */
  z-index: 0;
}
