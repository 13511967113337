@import '../../styles/customMediaQueries.css';
.root {
}
.desktopTopbar {
  box-shadow: none;
  /* position: fixed; */
  /* background-color: var(--backgroundColor); */
  @media (--viewportLarge) {
    box-shadow: none;
  }
}

.desktopTopbar .topbarMax {
  background-color: #fff;
}
