@import '../../../styles/customMediaQueries.css';
.root {
  background-color: white;
}
.heading {
  text-align: center;
  margin: 0;
  font-size: 25px;
  color: #500543;
  /* color: var(--colorBlack); */
  padding-top: unset;
  font-family: Poppins Bold;
  @media (--viewportLarge) {
    font-size: 48px;
    padding-top: 2rem;
  }
  /* margin-bottom: -4rem; */
}
.sectionOne {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  position: relative;
  margin-top: unset;
  @media (--viewportLarge) {
    margin-top: -10rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.right {
  width: 100%;
  @media (--viewportLarge) {
    width: 250px;
  }
}
.rightDesktop {
  width: 100%;
  display: none;
  @media (--viewportLarge) {
    width: 250px;
    display: block;
  }
}
.rightMobile {
  width: 100%;
  display: block;
  @media (--viewportLarge) {
    width: 250px;
    display: none;
  }
}
.center {
  display: none;
  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.centerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -1rem;

  @media (--viewportLarge) {
    display: none;
  }
}
.absolute {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 2rem;

  @media (--viewportLarge) {
    flex-direction: row;
    height: 100%;
    padding: 0;
  }
}
.signupImage {
  /* width: 460px; */
  width: auto;
  margin-bottom: 1rem;
  border-radius: 30px;
  height: 400px;
  max-width: 100%;
  z-index: 1;
  @media (--viewportMedium) {
    height: 500px;
    width: auto;
  }
  @media (--viewportLarge) {
    margin-bottom: unset;
    /* width: unset; */
    /* width: 460px; */
    width: auto;
    height: 400px;
    border-radius: 30px;
  }
}
.leftImage {
  width: 100%;
  height: 100%;
  @media (--viewportMedium) {
    width: unset;
  }
}
.leftLast {
  max-height: 900px;
}
.rightImage {
  width: 100%;

  height: 100%;
  @media (--viewportMedium) {
    width: unset;
  }
}
.oneImage {
  height: 100px;
  margin-bottom: 0.25rem;
  @media (--viewportSmall) {
    height: 150px;
  }
  @media (--viewportLarge) {
    /* height: auto; */
    height: 250px;
    margin-bottom: unset;
  }
  @media (--viewportLargeWithPaddings) {
    height: 310px;
  }
}
.sectionTwo {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: right;
  position: relative;
  margin-top: -3rem;
  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -20rem;
  }
}
.sectionThree {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  position: relative;
  margin-top: -4rem;
  @media (--viewportSmall) {
    margin-bottom: 5rem;
  }
  @media (--viewportMedium) {
    margin-bottom: 10rem;
  }
  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -20rem;
    margin-bottom: unset;
  }
}
.desktop {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}
.mobile {
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}
.cardHeading {
  font-size: 20px;
  color: #500543;
  /* color: var(--colorBlack); */
  margin: 0;
  line-height: 1.2;
  @media (--viewportLarge) {
    font-size: 30px;
  }
}
.redDot {
  color: #ffc24f;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 20%;
  left: 60%;
}
.redDotBig {
  color: #e30093;
  font-size: 100px;
  margin-right: 0.5rem;
  position: absolute;
  top: 80%;
  left: 5%;
}
.orangeDot {
  color: #e30093;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 55%;
  left: 30%;
}
.orangeDotRight {
  color: #ff5295;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 55%;
  right: 20%;
}
.redDotRight {
  color: #500543;
  font-size: 80px;
  margin-right: 0.5rem;
  position: absolute;
  top: 70%;
  right: 5%;
}
.redSmallLeft {
  color: #e30093;
  font-size: 70px;
  margin-right: 0.5rem;
  position: absolute;
  top: 30%;
  left: 5%;
}
.lineOne {
  position: absolute;
  transition: transform 0.3s ease;
  width: 100%;
  top: 60%;
  transform: rotate(20deg);
  z-index: 0;
}
.lineTwo {
  position: absolute;
  /* transition: transform 0.3s ease; */
  width: 100%;
  top: 40%;
  /* transform: rotate(20deg); */
  z-index: 0;
}
.lineThree {
  position: absolute;
  transition: transform 0.3s ease;
  width: 100%;
  top: 30%;
  transform: rotate(20deg);
  z-index: 0;
}
.linesSecond {
  position: absolute;
  transform: rotate(20deg);
  z-index: 0;
  top: 80%;
  width: 100%;
  transition: transform 0.3s ease;
}
