@import '../../../styles/customMediaQueries.css';
.root {
  background-image: url('../../../assets/Images/bgImage.svg');
  background-position: center center;
  background-repeat: repeat-x;
  background-size: contain;
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  justify-items: center;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
  }
}
.title {
  color: var(--marketplaceColor);
  margin-top: 0;
}
.right {
  display: grid;
  grid-template-columns: repeat(2, 150px);
  justify-items: center;
  gap: 1rem;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 200px);
  }
}
.image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  @media (--viewportMedium) {
    width: 180px;
    height: 180px;
  }
}
.userName {
  text-align: center;
}
.left {
  order: 2;
  margin-bottom: 2rem;
  @media (--viewportMedium) {
    order: unset;
    margin: auto;
  }
}
.button {
  border: 1px solid var(--marketplaceColor);
  padding: 0.5rem 2.5rem;
  color: var(--colorGrey800);
  margin: auto;
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    text-decoration: none;
  }
}
.subheading {
  margin: 0;
}
